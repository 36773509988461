var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row custom-cards"},_vm._l((_vm.priceQuotationAffiliateOperationsData),function(priceQuotationAffiliateOperation,index){return _c('CustomCard',{key:index,attrs:{"className":'col-sm-6 col-md-4 col-lg-3',"title":priceQuotationAffiliateOperation.priceQuotationAffiliateOperationTitleCurrent,"description":priceQuotationAffiliateOperation.fullCode,"imagePath":priceQuotationAffiliateOperation.priceQuotationAffiliateOperationImagePath,"defaultImg":_vm.defaultImg,"index":++index + _vm.filterData.currentIndex}},[_c('li',[_c('button',{attrs:{"title":_vm.$t('info')},on:{"click":function($event){_vm.setPriceQuotationAffiliateOperationData(
            priceQuotationAffiliateOperation
          );
          _vm.openBottomSheet('PriceQuotationAffiliateOperationInfo');}}},[_c('img',{attrs:{"src":require("@/assets/images/info.svg")}})])]),_c('li',[_c('button',{attrs:{"title":_vm.$t('general.qrCode')},on:{"click":function($event){_vm.setPriceQuotationAffiliateOperationData(
            priceQuotationAffiliateOperation
          );
          _vm.openBottomSheet('PriceQuotationAffiliateOperationQRCode');}}},[_c('img',{attrs:{"src":require("@/assets/images/qr-code.svg")}})])]),(_vm.checkPrivilege(_vm.hasPriceQuotationAffiliateOperationEdit()))?_c('li',[_c('button',{attrs:{"title":_vm.$t('edit')},on:{"click":function($event){_vm.setPriceQuotationAffiliateOperationData(
            priceQuotationAffiliateOperation
          );
          _vm.openBottomSheet('PriceQuotationAffiliateOperationUpdate');}}},[_c('img',{attrs:{"src":require("@/assets/images/pencil.svg")}})])]):_vm._e(),(_vm.checkPrivilege(_vm.hasPriceQuotationAffiliateOperationFinaleDelete()))?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.PriceQuotationAffiliateOperationDelete",modifiers:{"PriceQuotationAffiliateOperationDelete":true}}],attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setPriceQuotationAffiliateOperationData(
            priceQuotationAffiliateOperation
          )}}},[_c('img',{attrs:{"src":require("@/assets/images/trash.svg")}})])]):_vm._e(),(
        _vm.checkPrivilege(
          _vm.hasPriceQuotationAffiliateOperationChangeActivationType()
        )
      )?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.PriceQuotationAffiliateOperationChangeActivationType",modifiers:{"PriceQuotationAffiliateOperationChangeActivationType":true}}],attrs:{"title":_vm.$t('changeActivationType')},on:{"click":function($event){return _vm.setPriceQuotationAffiliateOperationData(
            priceQuotationAffiliateOperation
          )}}},[_c('img',{attrs:{"src":require("@/assets/images/changeActivationType.svg")}})])]):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('actionsData.modelName')},on:{"click":function($event){_vm.setPriceQuotationAffiliateOperationData(
            priceQuotationAffiliateOperation
          );
          _vm.openBottomSheet('ActionsData');}}},[_c('img',{attrs:{"src":require("@/assets/images/actions-data.svg")}})])])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }